@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Open+Sans&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.title-container {
  background: rgba(255, 255, 255, 0.8); /* Merge and adjust opacity as needed */
  padding: 1vw; /* Responsive padding */
  border-radius: 15px; /* Rounded corners */
  backdrop-filter: blur(10px); /* Frosted glass effect */
  border: 1px solid #ececec; /* Subtle border */
  transition: transform 0.3s, box-shadow 0.3s; /* Smooth transitions for hover effects */
}

/* Styles for chapter titles */
.chapter-title {
  font-family: 'Montserrat', sans-serif; /* Custom Google Font */
  color: #333;
  font-size: 3vw; /* Responsive font size */
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); /* Text shadow for depth */
  transition: color 0.3s, text-shadow 0.3s; /* Smooth transitions for hover effects */
}

.title-container:hover {
  transform: translateY(-5px); /* Slight lift effect on hover */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Shadow effect on hover */
}

.chapter-title:hover {
  color: #555; /* Darken color on hover */
  text-shadow: 0 2px 8px rgba(0, 0, 0, 0.6); /* Enhanced text shadow on hover */
}

/* Fade-in animation for chapter titles */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Apply the animation to each chapter title */
.chapter-title {
  animation: fadeInUp 1s ease-out forwards;
}

/* Button */
.button-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100%; /* Ensure the container takes up full height */
}

/* Box Flip */
.flip-container {
  perspective: 1000px;
}

.flipper {
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
}

.front, .back {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.front {
  z-index: 2;
  transform: rotateY(0deg);
}

.back {
  transform: rotateY(180deg);
}

.is-flipped .flipper {
  transform: rotateY(180deg);
}
